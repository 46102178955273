<template>
  <!--
    页面名称：我的题库-答案
    开发人：黄wj
  -->
  <div class="statistics">
    <div class="statistics-bg">
      <div class="statistics-bg-model">
        <div class="statistics-bg-model-n">
          <div class="statistics-bg-model-n1">{{title}}</div>
          <div class="statistics-bg-model-n2">
            <div class="statistics-bg-model-n2-name">{{name}}</div>
            <div>共{{total}}题</div>
          </div>
          <div class="statistics-bg-model-n3">
            <div class="model-n3-content" @click="see()">查看解析</div>
            <div class="model-n3-content" @click="before()">返回</div>
          </div>
        </div>
      </div>
    </div>
    <div class="statistics-content">
      <div class="statistics-content-model">
        <div class="statistics-content-model-n">
          <div class="statistics-content-model-n-name" v-if="pageWidth >= 1025">
            <div
              class="statistics-content-model-n-name-sum"
            >{{correct_data.length!=''?correct_data.length:'0'}}</div>
            <div class="statistics-content-model-n-name-anme">答对题数</div>
          </div>
          <div class="statistics-content-model-n-name" v-else>
            <div class="statistics-content-model-n-name-anme">答对题数</div>
            <div class="statistics-content-model-n-name-sum">
              {{correct_data.length!=''?correct_data.length:'0'}}
            </div>
          </div>
          <div class="statistics-content-model-n-line"></div>
          <div class="statistics-content-model-n-content">
            <div
              class="statistics-content-model-n-content-n"
              v-for="(item,index) in correct_data"
              :key="index"
            >{{item}}</div>
          </div>
        </div>

        <div class="statistics-content-model-n">
          <div class="statistics-content-model-n-name" v-if="pageWidth >= 1025">
            <div
              class="statistics-content-model-n-name-sum2"
            >{{error_data.length!=''?error_data.length:'0'}}</div>
            <div class="statistics-content-model-n-name-anme">答错题数</div>
          </div>
          <div class="statistics-content-model-n-name" v-else>
            <div class="statistics-content-model-n-name-anme">答错题数</div>
            <div
              class="statistics-content-model-n-name-sum2"
            >{{error_data.length!=''?error_data.length:'0'}}</div>
          </div>
          <div class="statistics-content-model-n-line"></div>
          <div class="statistics-content-model-n-content">
            <div
              class="statistics-content-model-n-content-n2"
              v-for="(item,index) in error_data"
              :key="index"
            >{{item}}</div>
          </div>
        </div>

        <div class="statistics-content-model-n">
          <div class="statistics-content-model-n-name" v-if="pageWidth >= 1025">
            <div
              class="statistics-content-model-n-name-sum3"
            >{{no_ans_data.length!=''?no_ans_data.length:'0'}}</div>
            <div class="statistics-content-model-n-name-anme">未答题数</div>
          </div>
          <div class="statistics-content-model-n-name" v-else>
            <div class="statistics-content-model-n-name-anme">未答题数</div>
            <div
              class="statistics-content-model-n-name-sum3"
            >{{no_ans_data.length!=''?no_ans_data.length:'0'}}</div>

          </div>
          <div class="statistics-content-model-n-line"></div>
          <div class="statistics-content-model-n-content">
            <div
              class="statistics-content-model-n-content-n3"
              v-for="(item,index) in no_ans_data"
              :key="index"
            >{{item}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getManagement } from "../../../http/api/statistics";
import {mapState} from "vuex";
export default {
  created() {
    //在data中定义的数据还没被绑定,所以不要在这里赋值
  },
  mounted() {
    //定义数据已经被绑定  可以在这里进行数据ajax绑定
    this.getManagement();
  },
  props: {
    //
  },
  data() {
    return {
      ans_id: "0",
      correct_data: [], //答对的
      error_data: [], //错的
      no_ans_data: [], //未答题
      name: "",
      title: "",
      total: "",

      //返回数据

      id: "",
      // //title
      sum: ""
    };
  },
  computed: {
    ...mapState(['pageWidth']),
  },
  methods: {
    before() {
      this.$router
        .push(`practice?id=${this.id}&name=${this.title}&sum=${this.sum}`)
        .then(() => {})
        .catch(err => err);
    },
    see(){
     
        this.$router
        .push(`seeAnalysis?ans_id=${this.ans_id}&title=${this.title}&name=${this.name}&crs_id=${this.id}&sum=${this.sum}`)
        .then(() => {})
        .catch(err => err);
    },
    getManagement() {
      this.id = this.$router.history.current.query.id;
      this.sum = this.$router.history.current.query.sum;
      this.name = this.$router.history.current.query.name;
      this.title = this.$router.history.current.query.title;
      this.ans_id = this.$router.history.current.query.ans_id;
      getManagement(this.ans_id).then(res => {
        // console.log(res);
        this.correct_data = res.correct_data;
        this.error_data = res.error_data;
        this.no_ans_data = res.no_ans_data;
        this.total =
          this.correct_data.length +
          this.error_data.length +
          this.no_ans_data.length;
      });
    }
  }
};
</script>

<style scoped>
.statistics-bg {
  width: 100%;
  height: 195px;
  background: url("../../../assets/img/answer.png");
  background-size: cover;
}
.statistics-bg-model {
  width: 67%;
  height: 185px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  margin: auto;
  color: white;
}
.statistics-content {
  width: 100%;
}
.statistics-content-model {
  position: relative;
  width: 67%;
  margin: auto;
  top: -40px;
}
.statistics-content-model-n {
  padding: 25px 24px;
  border-radius: 6px;
  background: white;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.statistics-content-model-n-line {
  width: 1px;
  height: 36px;
  background: #e4e4e4;
  margin: 0 19px;
}
.statistics-content-model-n-name {
  text-align: center;
}
.statistics-content-model-n-name-sum {
  font-size: 21px;
  color: #0080ff;
  text-align: center;
  font-weight: 600;
}

.statistics-content-model-n-name-sum2 {
  font-size: 21px;
  color: #fc6257;
  text-align: center;
  font-weight: 600;
}
.statistics-content-model-n-name-sum3 {
  font-size: 21px;
  color: #657489;
  text-align: center;
  font-weight: 600;
}
.statistics-content-model-n-name-anme {
  font-size: 12px;
  color: #657489;
  margin-top: 5px;
  width: 64px;
}
.statistics-content-model-n-content {
  display: flex;
  flex-wrap: wrap;
}
.statistics-content-model-n-content-n {
  width: 30px;
  height: 30px;
  background: #0080ff;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: white;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.statistics-content-model-n-content-n2 {
  width: 30px;
  height: 30px;
  background: #fc6257;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: white;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.statistics-content-model-n-content-n3 {
  width: 30px;
  height: 30px;
  background: #657489;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: white;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
  text-align: center;
}
.statistics-bg-model-n {
  margin: auto;
  position: relative;
  top: -15px;
}
.statistics-bg-model-n1 {
  font-size: 22px;
  text-align: center;
  margin: auto;
}
.statistics-bg-model-n2 {
  display: flex;

  text-align: center;
  justify-content: center;
  font-size: 15px;
  margin-top: 10px;
}
.statistics-bg-model-n3 {
  display: flex;
  margin: 10px;
  text-align: center;
}
.statistics-bg-model-n2-name {
  margin-right: 10px;
}
.model-n3-content {
  width: 100px;
  text-align: center;
  font-size: 14px;
  border-radius: 3px;
  padding: 5px 0;
  background: rgba(35, 118, 227, 0.747);
  margin: 0 5px;
  margin-top: 5px;
  cursor: pointer;
}
.model-n3-content:hover {
  background: rgb(35, 118, 227);
  transition: 0.5s;
}
/* 移动端 */
@media screen and (max-width: 1024px) {
  .statistics-bg-model,
  .statistics-content-model {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
  .statistics-content-model-n {
    display: block;
  }
  .statistics-content-model-n-name {
    display: flex;
    height: 36px;
    line-height: 36px;
  }
  .statistics-content-model-n-name-anme {
    margin-top: 0;
    font-size: 21px;
    width: 100px;
    font-weight: 600;
  }
  .statistics-content-model-n-line {
    width: 100%;
    height: 1px;
    background: #e4e4e4;
    margin: 5px 0;
  }
}
</style>
