import request from '../request'


//统计
export function getManagement(ans_id) {
    return request({
        url: `answer/mobile/management?ans_id=${ans_id}`,
        method: 'get',

    })
}